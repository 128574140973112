<template>
  <div class="guarantee-look">
    <backHeader  C_Title='guaranteeSlip'></backHeader>
    <div class="guarantee-look-list">
      <div>
        <h3>申请批改</h3>

        <div>
          <el-button type="warning" @click="add()" :disabled="is_add==1?false:true">我要添加</el-button>
          <!-- <el-popover
              placement="bottom"
              title="标题"
              width="200"
              trigger="click"
              content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
            <el-button style="background:#944DFF;color: white;margin: 0 10px" slot="reference">我要替换</el-button>
          </el-popover> 
          <el-button type="danger">下载批量模版</el-button>
          <el-button type="primary">上传批量模版</el-button>-->
        </div>
      </div>
      <div class="guarantee-look-list-2">
        <div><img src="../../image/guaranteeLook/1.png" alt=""></div>
        <div>
          <div>保单号：{{policy_num}}</div>
          <div>
            <div>流水号：{{num_id}}</div>
            <div>创建时间：{{c_time}}</div>
            <div> 保障期限：{{s_time}} 至 {{e_time}}</div>
          </div>
          <div>保费： <span>¥ {{policy_money}}</span></div>
        </div>
        <div>
          <div>
            <div>产品名称：{{product_name}}</div>
            <div>身故残疾：{{Safeguard[0]}}</div>
          </div>
          <div>
            <div>保险公司：{{company_name}}</div>
            <div>意外医疗：{{Safeguard[1]}}</div>
          </div>
          <div>
            <div>职位类别：{{job_name}}</div>
            <div>误工费：{{Safeguard[2]}}</div>
          </div>
          <div>
            <div>工种：{{work_name}}</div>
          </div>
          <div>
            <div>扩展方案：</div>
          </div>
        </div>
      </div>
      <div class="guarantee-look-list-3">
        <div>

        </div>
      </div>
      <div class="guarantee-look-list-4">
        <div>
          <div>在保人员</div>
        </div>
      </div>
      <div class="guarantee-look-list-5">
        <div>
          <div>
            <div>
              <div>姓名：</div>
              <el-input v-model="searchName" placeholder="请输入内容"></el-input>
              <div>身份证号：</div>
              <el-input v-model="searchIdCard" placeholder="请输入内容"></el-input>
            </div>
            <div>
              <el-button type="primary" @click="search()">查询</el-button>
              <el-button @click="reset()">重置</el-button>
            </div>
          </div>
          <div>
            <el-table
                align="center"
                :data="tableData"
                border
                max-height="500px"
                style="width: 100%">
              <el-table-column
                  align="center"
                  type="index"
                  width="80px"
                  label="序号" >
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="name"
                  label="姓名">
                  <template slot-scope="scope">
                      <div class="c-tj" :class="scope.row.addtype==1||scope.row.addtype==0?'add':''">
                          <input type="text" v-model="scope.row.name" placeholder="请输入" :disabled="scope.row.addtype==1||scope.row.addtype==0?false:true">
                      </div>
                  </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="paper_num"
                  label="身份证号">
                  <template slot-scope="scope">
                      <div class="c-tj"  :class="scope.row.addtype==1||scope.row.addtype==0?'add':''">
                          <input type="text" v-model="scope.row.paper_num" placeholder="请输入" :disabled="scope.row.addtype==1||scope.row.addtype==0?false:true" @change="sendData(scope.row,scope.$index)">
                      </div>
                  </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="job_name"
                  label="工作种类">
                  <template slot-scope="scope">
                      <div>
                          <span>{{scope.row.job_name=="" ? job_name :scope.row.job_name}}</span>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="money"
                  width="100px"
                  label="保费金额">
                  <template slot-scope="scope">
                      <div>
                          <span>{{scope.row.money=="" ? year_money:scope.row.money}}</span>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  label="保费期限">
                  <template slot-scope="scope">
                      <div>
                          <span>{{scope.row.s_time}} 至 {{scope.row.e_time}}</span>
                      </div>
                  </template>
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="remark"
                  label="备注">
              </el-table-column>
              <el-table-column
                  align="center"
                  prop="id"
                  width="120px"
                  label="操作">
                <template slot-scope="scope">
                  <el-button type="danger" size="mini" v-if="scope.row.addtype==0" @click="del(scope.$index,scope.row.name)">删除</el-button>
                  <el-button type="primary" size="mini" v-else @click="replace(scope.$index,scope.row.name)" :disabled="is_change==1?false:true">替换</el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- <div style="width: 100%;display: flex;justify-content: flex-end;margin-top: 10px">
              <el-pagination
                  @size-change="handleSizeChange(2)"
                  @current-change="handleCurrentChange(2)"
                  :current-page.sync="currentPage2"
                  :page-size="100"
                  layout="total, prev, pager, next"
                  :total="1000">
              </el-pagination>
            </div> -->
          </div>
          <div>
            <!-- <div>选择生效日期：
              <el-date-picker
                  v-model="sTime"
                  type="date"
                  placeholder="选择日期">
              </el-date-picker>
            </div> -->
            <div>生效日期：{{min_date}}</div>
            <div>
              应缴纳变更保费：<span style="color:#E93D39;font-size: 24px;">{{totalMoney}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="guarantee-look-list-6">
        <el-button type="primary" @click="subApply()">提交申请</el-button>
      </div>
    </div>
    <pay ref="payVisible"  :paytype="pay.payDis" :payDis="pay.payDis" :num_id='num_id' :company_bank_name='pay.company_bank_name' :company_bank_no="pay.company_bank_no" :company_name="pay.company_name" :totalMoney="pay.totalMoney" :change_id="pay.change_id"></pay>

  </div>
</template>

<script>
import backHeader from "../../components/back/backHeader";
import pay from "../../components/back/pay";

    export default {
        name: "guaranteeLook",
        components: {
            backHeader,
            pay
        },
        data() {
            return {
                num_id:'',//流水号
                check_status:'',//保单状态
                change_id:"",//批单号
                baseUrl:"",
                product_name:"",//产品名
                policy_num:"",//保单号
                Safeguard:"",//保障
                c_time:"",//创建时间
                s_time:"",//开始时间
                e_time:"",//终止时间
                policy_money:"",//保费
                company_name:'',//保险公司
                job_name:"",// 职位类别
                work_name:"",//工种
                sTime:"",//生效日期
                searchName:'',//姓名搜索用
                searchIdCard:'',//id搜索用
                tableData: [],//表格渲染数据
                newData:[],//发送后台数据
                keepData:[],//查询时数据留存
                year_money:"",//添加人员使用单个保费
                cid:0,//自己创建用于记录增加替换id
                max_date:'',//可选择的投保时间范围
                min_date:'',//可选择的投保时间范围
                totalMoney:"0",//添加人员使用要缴纳保费
                addNum:0,//添加人数
                is_add:1,//是否可以添加
                is_change:1,//是否可以替换
                // payVisible:false,//支付弹窗
                pay:{
                  company_bank_name:'',//线下支付
                  company_bank_no:'',//线下支付
                  company_name:'',//线下支付
                  totalMoney:'',//线下支付
                  change_id:'',
                  payDis:''//禁用哪种支付方式 0选线上 1选线下
                }
            }
        },
        created() {
            this.num_id = this.$route.query.num_id
        },
        mounted() {
            this.getCheckDetail()
        },
        methods: {
            //订单数据
            getCheckDetail:function(){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: that.$store.state.baseUrl + '/api/product/order/order_check',
                    method: 'POST',
                    params:{
                        num_id:that.num_id,
                        // num_id:2003120487897
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        that.product_name=res.data.data.product_name,//产品名
                        that.policy_num=res.data.data.policy_num,//保单号
                        that.Safeguard=res.data.data.Safeguard,//保障
                        that.c_time=res.data.data.c_time,//创建时间
                        that.s_time=res.data.data.s_time,//开始时间
                        that.e_time=res.data.data.e_time,//终止时间
                        that.policy_money=res.data.data.policy_money,//保费
                        that.company_name=res.data.data.company_name,//保险公司
                        that.job_name=res.data.data.job_name,// 职位类别
                        that.work_name=res.data.data.work_name//工种
                        that.tableData=res.data.data.nowData
                        that.year_money=res.data.data.year_money//添加单人费用
                        that.max_date=res.data.data.min_date.max_date,//可选择的投保时间范围
                        that.min_date=res.data.data.min_date.min_date//可选择的投保时间范围
                        that.is_add=res.data.data.is_add
                        that.is_change=res.data.data.is_change
                    }else{
                        that.$message.error("加载失败请稍后重试");
                    }              
                }).catch(function (error) {
                    console.log(error);
                });   
            },
            // 添加
            add:function(){
              this.cid=this.cid+1
              let _data={
                name:'',
                paper_num:'',
                job_name:'',
                money:'',
                addtype:0,
                cid:this.cid
              }
              console.log(_data)
              if (this.tableData[0].name != '' && this.tableData[0].paper_num != '') {
                this.tableData.unshift(_data)
                this.addNum++
                this.totalMoney= parseFloat(this.year_money) * this.addNum
              } else {
                this.$message.warning('您的上一条信息还没有写入数据');
              }
            },
             // 删除人员
            del: function (index, name) {
              this.$confirm('您确定要删除' + name + '吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
                this.tableData.splice(index, 1)
                this.addNum--
                this.totalMoney= parseFloat(this.year_money) * this.addNum
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                });
              })
            },
            // 替换
            replace:function(index,name){
                this.$confirm('您确定要替换' + name + '吗?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.cid=this.cid+1
                  this.tableData[index].addtype=1
                  this.tableData[index].cid=this.cid
                  this.tableData[index].old_name=this.tableData[index].name
                  this.tableData[index].old_paper_num=this.tableData[index].paper_num
                  this.$set(this.tableData, index,this.tableData[index]);
                })
            },
            //添加替换获取数据
            sendData:function(e,i){
              console.log(e)
              let that=this
              let cda=false
              if(this.idNoFormatCheck(e.paper_num)){
                if(that.tableData[i].old_paper_num!=e.paper_num){
                  for (let index = 0; index < that.tableData.length; index++) {
                    if(index!=i){
                      if(that.tableData[index].paper_num ==e.paper_num){
                         that.$message.error("被替换身份证号已存在");
                         return
                      }else{
                          if(that.newData.length>0){
                            for (let index = 0; index < that.newData.length; index++) {
                                  if(that.newData[index].cid == e.cid){
                                    that.newData[index].name=e.name
                                    that.newData[index].paper_num=e.paper_num
                                    console.log(that.newData)
                                    return
                                  }else{
                                    cda=true
                                  }
                            }
                          }else{
                              cda=true
                          }
                      }
                    }
                  }
                  if(cda){
                    that.newData.push(e)
                    console.log(that.newData)
                  }
                }else{
                  that.$message.error("身份证号和被替换时数据相同");
                }
              }else{
                that.$message.error("身份证号不正确");
              }


            },
            //搜索
            search:function(){
                let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                this.keepData=this.tableData
                that.axios({
                    url: that.$store.state.baseUrl + '/api/product/order/searchEmployee',
                    method: 'POST',
                    params:{
                        num_id:that.num_id,
                        // num_id:2003120487897,            
                        name:that.searchName,
                        paper_num:that.searchIdCard
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                       that.tableData=res.data.data
                    }else{
                        that.$message.error("加载失败请稍后重试");
                    }              
                }).catch(function (error) {
                    console.log(error);
                });   
            },
            // 重置
            reset:function(){
                this.tableData=this.keepData
                this.searchName="",
                this.searchIdCard=""
            },
            //提交申请
            subApply:function(){
              let that=this;
                let loading=that.$loading({
                    lock: true,
                    text: 'Loading',
                    background: 'rgba(0, 0, 0, 0.5)'
                });
                that.axios({
                    url: that.$store.state.baseUrl + '/api/product/order/checkOrderSubmit',
                    method: 'POST',
                    params:{
                        num_id:that.num_id,
                        // num_id:"R008181270528",//线上
                        // num_id:2003120487897,//线下
                        uid:that.$store.state.logininformation.uid,
                        members:JSON.stringify(that.newData),
                        money:that.totalMoney,
                        year_money:that.year_money
                    }
                }).then(function(res) {
                    console.log(res)
                    loading.close();
                    if(res.data.code==200){
                        if(res.data.data.is_replace==0){//替换成功不需要要付费转跳批单页
                          that.$confirm('替换成功', '提示', {
                            confirmButtonText: '确定',
                            type: 'success',
                            center: true,
                            showCancelButton:false
                          }).then(() => {
                            that.$router.push({
                                name:"correctOrder"
                            })
                          })                          
                        }else{
                            that.$refs.payVisible.open()
                            that.pay.company_bank_name=res.data.data.company_bank_name,//线下支付
                            that.pay.company_bank_no=res.data.data.company_bank_no,//线下支付
                            that.pay.company_name=res.data.data.company_username,//线下支付
                            that.pay.totalMoney=res.data.data.money//线下支付
                            that.pay.change_id=res.data.data.change_id
                            that.pay.payDis=res.data.data.pay_type+'' //1线下支付 
                        }
                    }else{
                        that.$message.error(res.data.msg);
                    }              
                }).catch(function (error) {
                    console.log(error);
                });   
            },



            idNoFormatCheck: function (value) {//验证信息
                let num = value.toUpperCase();
                // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
                let reg = /^(\d{18,18}|\d{15,15}|\d{17,17}X)$/;
                if (!reg.test(num)) {
                  return false;
                }
                // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
                // 下面分别分析出生日期和校验位
                let len, re;
                len = num.length;
                if (len == 15) {
                  re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/);
                  let arrSplit = num.match(re);
                  // 检查生日日期是否正确
                  let dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4]);
                  let bGoodDay;
                  bGoodDay = (dtmBirth.getYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
                  if (!bGoodDay) {
                    return false;
                  }
                }
                if (len == 18) {
                  re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/);
                  let arrSplit = num.match(re);
                  // 检查生日日期是否正确
                  let dtmBirth = new Date(arrSplit[2] + "/" + arrSplit[3] + "/" + arrSplit[4]);
                  let bGoodDay;
                  bGoodDay = (dtmBirth.getFullYear() == Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) == Number(arrSplit[3])) && (dtmBirth.getDate() == Number(arrSplit[4]));
                  if (!bGoodDay) {
                    return false;
                  } else {
                    // 检验18位身份证的校验码是否正确。
                    // 校验位按照ISO 7064:1983.MOD
                    // 11-2的规定生成，X可以认为是数字10。
                    let valnum;
                    let arrInt = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2);
                    let arrCh = new Array('1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2');
                    let nTemp = 0;
                    for (let i = 0; i < 17; i++) {
                      nTemp += num.substr(i, 1) * arrInt[i];
                    }
                    valnum = arrCh[nTemp % 11];
                    if (valnum != num.substr(17, 1)) {
                      return false;
                    }
                  }
                }
                return true;
              },
        },
    }
</script>

<style scoped lang="scss">
.guarantee-look {
  // position: fixed;
  // top: 0;
  // bottom: 0;
  width: 100%;
  background: #F4F3F6;
  overflow-y: scroll;
  padding-top:70px
}

.guarantee-look-list {
  width: 70%;
  margin-left: 15%;

  & > div:nth-child(1) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #F3F5FB;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 20px;

    & > h3 {
      padding: 0;
      margin: 0;
    }

    & > div:nth-child(2) {
      display: flex;
      justify-content: flex-end;
    }
  }

  .guarantee-look-list-2 {
    display: flex;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 20px;
    background: white;

    & > div:nth-child(1) {
      width: 15%;
    }

    & > div:nth-child(2) {
      & > div {
        width: 100%;
        text-align: left;
      }

      & > div:nth-child(1) {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 54px;
      }

      & > div:nth-child(2) {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(101, 101, 101, 1);
        line-height: 30px;
      }

      & > div:nth-child(3) {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(101, 101, 101, 1);
        line-height: 30px;

        & > span {
          color: #E93D39;
          font-size: 24px;
        }
      }

      width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
    }

    & > div:nth-child(3) {
      margin-top: 40px;
      width: 50%;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba(101, 101, 101, 1);
      line-height: 30px;

      & > div {
        display: flex;
        justify-content: space-between;

        & > div:nth-child(2) {
          width: 40%;
          text-align: left;
        }
      }
    }
  }

  .guarantee-look-list-3 {
    display: flex;
    justify-content: flex-start;
    background: white;

    & > div {
      width: 100%;
      border-top: 1px solid rgba(243, 243, 245, 1);
      margin: 40px 20px 40px 20px;
      padding-left: 15%;
      text-align: left;
    }
  }

  .guarantee-look-list-4 {
    width: 100%;
    background: white;
    height: 48px;

    & > div:nth-child(1) {
      margin: 0 20px;
      background: rgba(243, 243, 245, 1);
      display: flex;

      & > div {
        width: 150px;
        text-align: center;
        line-height: 48px;
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        cursor: pointer;
      }

      .chooseIt {
        color: #0053B9;
        border-bottom: 2px solid #0053B9;
      }
    ;
    }
  }

  .guarantee-look-list-5 {
    width: 100%;
    background: white;
    padding-bottom: 40px;
    .c-tj{
        font-size: 14px;
        color: #606266;
        input{
          font-size: 14px;
          color: #606266;
          border: 0;
          text-align: center;
          background: transparent;
          line-height: 26px;
        }
        input:focus{
          border: 0;
          outline: 0;
        }
    }
    .c-tj.add input{
      border-bottom: 1px solid #6495ED;
    }
    & > div {
      padding: 20px;

      & > div:nth-child(1) {
        display: flex;
        justify-content: space-between;

        & > div {
          margin-top: 20px;
          margin-bottom: 20px;
          display: flex;
          align-items: baseline;

          & > div:nth-child(1) {
            width: 150px;
          }

          & > div:nth-child(3) {
            margin-left: 15px;
            width: 210px;
          }
        }
      }

      & > div:nth-child(3) {
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: baseline;

        & > div:nth-child(2) {
          margin-left: 10%;
        }
      }
    }
  }

  .guarantee-look-list-6 {
    margin-top: 40px;
    margin-bottom: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
